import React, { useEffect, useState } from "react";
import BackIntro from "../../Components/Cards/BackIntro";
import { useLocation, useParams } from "react-router-dom";
import scrollToTop from "../../Components/ScrollTop/ScrollTop";

const Exercise = () => {
  const { id } = useParams();
  const { state } = useLocation();
  const [quizzes, setQuizzes] = useState([]);
  const [showResult, setshowResult] = useState(false);

  useEffect(() => {
    const newQuizzes = state.quizzes.reduce(
      (result, item) => [...result, { ...item, user_answer: null }],
      []
    );
    setQuizzes(newQuizzes);
  }, []);

  React.useEffect(() => {
    scrollToTop();
  }, []);

  const getInputStyle = (item) => {
    if (showResult) {
      if (item.user_answer?.toUpperCase() === item.answer?.toUpperCase()) {
        return "right";
      } else if (
        item.user_answer?.toUpperCase() !== item.answer?.toUpperCase()
      ) {
        return "worng";
      }
    }
    return "";
  };

  const getQuestionInput = (item, index) => {
    const splitQues = item?.question?.split("___");
    // console.log('splitQues', splitQues);

    return (
      <li key={index}>
        <div className="exc_item">
          <span>{index + 1}</span>
          <span>{splitQues[0]}</span>
          <input
            onChange={(e) => {
              const newQuizzes = [...quizzes];
              newQuizzes[index].user_answer = e.target.value;
              setQuizzes(newQuizzes);
            }}
            className={getInputStyle(item)}
            type="text"
            placeholder="Write your answer"
            value={quizzes[index].user_answer}
          />
          <span>{splitQues[1]}</span>
        </div>

        {/* answer show area */}
        {showResult && (
          <div className="ans_area">
            <span>{item?.answer}</span>
          </div>
        )}
      </li>
    );
  };

  // console.log("quizzes", quizzes);
  return (
    <div className="Exc_container">
      <BackIntro title="Lessons" />

      <div className="exc_main">
        <h2 className="c_title">{`${state?.title} exercise`}</h2>
        <div className="exe_box">
          <ul>{quizzes.map((item, index) => getQuestionInput(item, index))}</ul>
          <button
            onClick={() => setshowResult(!showResult)}
            className="btn exc_btn"
          >
            {showResult ? "Hide Result" : "See Result"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Exercise;
