import React from 'react';

const Requerments = ({ documents }) => {

    return (
        <div className='req_main'>
            <h3>{documents?.label}</h3>
            <ul className='requerments'>
                {
                    documents?.requirement_lists?.map((document,index) => (
                        <li key={document.id}>
                            <span>{index+1}</span> <span>{document.label}</span>
                        </li>
                    ))
                }
            </ul>
        </div>
    );
};

export default Requerments;