import ky from "ky";
import Urls from "./Urls";
import { toast_error } from "../Toast/Toast";
// import store from '../store/Store';
// import {hideSpinner, showSpinner} from '../store/app/appSlice';
const abortController = new AbortController();

export const SUCCESS = "success";
export const ERROR = "error";
export const UNAUTHORIZED = "unauthenticated";
export const VALIDATIONERROR = "ValidationError";

const extendedKy = ky.extend({
  prefixUrl: Urls.baseUrl,
  hooks: {
    beforeRequest: [
      (request) => {
        // Do something before every request
        // This is a good place to authorize request if needed
        request.headers.set("Authorization", `Bearer ${global.accessToken}`);
        // console.log('request response-------->', request);
      },
    ],
    beforeError: [
      (error) => {
        // const {response} = error;
        // console.log('error response-------->', error);
        // if (response && response.body) {
        //   error.name = 'GitHubError';
        //   error.message = `${response.body.message} (${response.status})`;
        // }
        return error;
      },
    ],
  },
  // retry: {
  //   statusCodes: [401],
  // },
  timeout: 60000,
  signal: abortController.signal,
});

const Client = async (method, url, params, options) => {
  try {
    // console.log("URL ==>", url);
    // console.log("Payload ==>", params);

    // if (options?.showIndicator) {
    //   // console.log('handle show indicator');
    //   unstable_batchedUpdates(() => store.dispatch(showSpinner()));
    // }

    let response;

    //handle different request method
    if (method === "GET") {
      response = await extendedKy.get(url).json();
    } else if (method === "POST") {
      response = await extendedKy.post(url, { json: params }).json();
    } else if (method === "FILE") {
      response = await extendedKy.post(url, { body: params }).json();
    } else if (method === "PUT") {
      response = await extendedKy.put(url, { json: params }).json();
    } else if (method === "PATCH") {
      response = await extendedKy.patch(url, { json: params }).json();
    } else if (method === "DELETE") {
      response = await extendedKy.delete(url, { json: params }).json();
    }

    // console.log('clientResponse', response);

    // if (options?.showIndicator) {
    //   // console.log('handle hide indicator');
    //   unstable_batchedUpdates(() => store.dispatch(hideSpinner()));
    // }

    //checking resposone status
    if (response?.status == SUCCESS) {
      if (options?.onSuccess) {
        // console.log("onSuccess response ==>> ", response);
        options.onSuccess(response);
      }

      return response;
    } else {
      //if status is fail and have some message then show toast text
      if (!options?.hideToast && response?.message) {
        toast_error(response?.message);
      }

      //called error callback with error response
      if (options?.onError) {
        console.log("onError response ==>> ", response);
        options.onError(response?.response);
      }

      return response;
    }
  } catch (error) {
    // if (options?.showIndicator) {
    //   // console.log('handle hide indicator');
    //   unstable_batchedUpdates(() => store.dispatch(hideSpinner()));
    // }

    // console.log('error-->', error);
    let errorData;

    if (error.response) {
      try {
        errorData = await error.response.json();
      } catch (e) {
        console.error("couldn't parse error response for specific message");
      }
    }

    // console.log('clientError', errorData);

    //start if have some message then show toast text
    if (!options?.hideToast && errorData?.message) {
      toast_error(errorData?.message);
    } else if (!options?.hideToast && errorData?.response?.message) {
      toast_error(errorData?.response?.message);
    }
    //end show toast text code

    if (options?.onError) {
      options.onError(errorData);
    }

    return errorData;
  }
};

export default Client;
