import Client from "../client";

export default {
  // returns list of all the Payment route
  paymentSuccess: (paymentId, token, payerId, options) =>
    Client(
      "GET",
      `success?paymentId=${paymentId}&token=${token}&PayerID=${payerId}`,
      {},
      options
    ),
};
