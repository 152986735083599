import React, { useContext } from "react";
import { Link } from "react-router-dom";
import theory from "../Assets/theory.svg";
import quiz from "../Assets/quiz.svg";
import BackIntro from "../Components/Cards/BackIntro";
import useTitle from "../Hooks/useTitle";
import { AuthContext } from "../Context/AuthProvider";
import scrollToTop from "../Components/ScrollTop/ScrollTop";

const NcPatentee = () => {
  useTitle("Patentee");
  const { user } = useContext(AuthContext);

  React.useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div className="NcPat_container">
      <BackIntro title={"Education"} />
      <div className="NcPat_main">
        <h2 className="c_title">NC PATENTEE</h2>
        <div className="NcPat_sub">
          <div className="NcPat_box">
            <Link className="green" to={`/patente/lessons`}>
              <div className="nc_item">
                <span className="nc_text">
                  TEORIA - Tuttle le lezioni
                  <br />
                  সকল চ্যাপ্টার এর ক্লাস
                </span>
                <img className="nc_img" src={theory} alt="" />
              </div>
            </Link>

            <Link
              className="pink"
              to={user === null ? "/login" : "/patente/quiz/simulation"}
              // state={{ type: "simulation" }}
              // onClick={() => {
              //   if (user == null) {
              //     return navigate("login");
              //   } else if (patenteLessons?.user_has_course) {
              //     return navigate("/patente/quiz", {
              //       state: { type: "simulation" },
              //     });
              //   } else {
              //     console.log("pls request");
              //   }
              // }}
            >
              <div className="nc_item">
                <span className="nc_text">
                  Simulazione di Esame
                  <br />
                  পরিক্ষা অনুরূপ কুইজ
                </span>
                <img className="nc_img" src={quiz} alt="" />
              </div>
            </Link>

            <Link
              className="green"
              to={user === null ? "/login" : "/patente/capitoli-selection"}
              // to={`/patente/quiz`}
              // onClick={() => {
              //   if (user == null) {
              //     return navigate("login");
              //   } else if (patenteLessons?.user_has_course) {
              //     navigate("/patente/capitoli-selection");
              //   } else {
              //     console.log("pls request");
              //   }
              // }}
            >
              <div className="nc_item">
                <span className="nc_text">
                  Quiz per Capitoli
                  <br />
                  চ্যাপ্টার অনুসারে কুইজ
                </span>
                <img className="nc_img" src={quiz} alt="" />
              </div>
            </Link>
            {/* <div className='pink'>
              <Link to={`/patente/quiz`}>Quiz (ঈসে)</Link>
              <img src={quiz} alt="" />
          </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NcPatentee;
