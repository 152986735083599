import React from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import BackIntro from "../../Components/Cards/BackIntro";
// import Video from "../../Components/Cards/Video";
import Vimeo from "@u-wave/react-vimeo";
import Urls from "../../api/Urls";
import amico from "../../Assets/amico.png";
import { Document, Page, pdfjs } from "react-pdf";
import thepdf from "../../Assets/pdf.pdf";
import scrollToTop from "../../Components/ScrollTop/ScrollTop";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const LessonDetails = () => {
  const { course } = useParams();
  const { state } = useLocation();

  const pdfUrl = Urls.storagePath + state.data.file_path;
  // const pdfUrl = thepdf;

  const [numPages, setNumPages] = React.useState(null);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  React.useEffect(() => {
    scrollToTop();
  }, []);

  console.log(state);

  // console.log("course", course);
  return (
    <div className="course_contaner">
      <BackIntro title="Lesson list" />

      <div className="couser_main">
        <h2 className="c_title">{state.data.class_name}</h2>
        <div className="course_demo_info">
          {state.data.video_url && (
            <div className="video">
              <Vimeo video={state.data.video_url} style={{ width: "100%" }} />
            </div>
            //   <Video url={Urls.storagePath + state.data.video_url} />
          )}
          <div className="pdf-container">
            <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
              {Array.from(new Array(numPages || 0), (el, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1} />
              ))}
            </Document>
          </div>
        </div>

        {/* excercise */}
        {state.data?.quizzes?.length > 0 && (
          <div className="day_box">
            <div className="amico_img">
              <img src={amico} alt="" />
            </div>
            <Link
              to={`/${course}/lessons/${state.data.id}/exercise`}
              state={{
                title: state.data.class_name,
                quizzes: state.data.quizzes,
              }}
              className="btn exc_btn"
            >
              Try Exceries
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default LessonDetails;
