import Client from "../client";

export default {
  // returns list of all the Car route
  updatePlayerId: (params, options) =>
    Client('POST', 'notification-setting', params, options),

  getNotifications: (pageNumber, options) =>
    Client('GET', `notifications?page=${pageNumber}`, undefined, options),

  readNotification: (id, options) =>
    Client('POST', `read/${id}`, undefined, options),

  realAllNotifications: (options) =>
    Client('GET', 'all-read', {}, options),
};
