export const getRequest = async (apiUrl) => {
  // console.log('appUrl', apiUrl);
  const response = await fetch(apiUrl, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      // 'Accept-Language': globalAny.activeLangTag,
    },
  });
  const result = await response.text();

  return result;
};

export const authenticateGetRequest = async (apiUrl) => {
  // console.log('appUrl', apiUrl);
  const response = await fetch(apiUrl, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + global.accessToken,
      // 'Accept-Language': globalAny.activeLangTag,
    },
  });
  // console.log('response', response)
  const result = await response.text();

  return result;
};

export const postRequest = async (apiUrl, body) => {
  // console.log(apiUrl, body);
  const response = await fetch(apiUrl, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      // 'Accept-Language': globalAny.activeLangTag,
    },
    body: JSON.stringify(body),
  });

  const result = await response.text();

  return result;
};

export const authenticatePostRequest = async (apiUrl, body) => {
  // console.log(apiUrl, body, global.accessToken);
  const response = await fetch(apiUrl, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + global.accessToken,
      // 'Accept-Language': globalAny.activeLangTag,
    },
    body: JSON.stringify(body),
  });
  const result = await response.text();

  return result;
};

export const deleteRequest = async (apiUrl, body) => {
  // console.log('appUrl', apiUrl)
  const response = await fetch(apiUrl, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      // 'Accept-Language': globalAny.activeLangTag,
    },
    body: JSON.stringify(body),
  });
  const result = await response.text();

  return result;
};

export const authenticateDeleteRequest = async (apiUrl, body) => {
  // console.log('appUrl', apiUrl)
  const response = await fetch(apiUrl, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + global.accessToken,
      // 'Accept-Language': globalAny.activeLangTag,
    },
    body: JSON.stringify(body),
  });
  const result = await response.text();

  return result;
};

export const multipartPostRequest = async (apiUrl, data) => {
  // console.log( 'data', data)
  const formData = new FormData();
  data.map((item) => formData.append('file', item));

  const response = await fetch(apiUrl, {
    method: 'POST',
    body: formData,
  });

  const result = await response.text();

  return result;
};

export const multipartAuthenticatePostRequest = async (apiUrl, formData) => {
  // console.log( 'data', data)
  // const formData = new FormData();
  // formData.append('file', data);

  const response = await fetch(apiUrl, {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + global.accessToken,
    },
    body: formData,
  });

  const result = await response.text();
  // console.log('mULTIPLEiMAGE',result)
  return result;
};

const ApiProvider = {
  getRequest,
  postRequest,
  deleteRequest,
  multipartPostRequest,
  authenticateGetRequest,
  authenticatePostRequest,
  authenticateDeleteRequest,
  multipartAuthenticatePostRequest,
};

export default ApiProvider;
