import Client from "../client";

export default {
  // returns list of all the CAF route
  getCafs: (options) => Client("GET", "services", undefined, options),

  getCafDetails: (id, options) =>
    Client("GET", `service/${id}`, undefined, options),

  getUserCafs: (options) => Client("GET", "user-services", undefined, options),

  getUserCafDetails: (id, options) =>
    Client("GET", `user-service/${id}`, undefined, options),

  validateRequestCaf: (params, options) =>
    Client("POST", "validate-service-request", params, options),

  requestCaf: (params, options) =>
    Client("FILE", "request-service", params, options),
};
