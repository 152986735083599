import React from "react";
import image from "../../Assets/noman2.png";
import scrollToTop from "../ScrollTop/ScrollTop";

const SignupSuccess = () => {
  const handleClick = () => {
    window.location.href = "/login";
  };

  React.useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div className="container">
      <div className="success_container">
        <img src={image} alt="" />
        <span>
          thanks for submitting all info <br />
          our staff will contact you soon
        </span>
        <div className="suc_btn">
          <button onClick={handleClick} className="btn">
            Log in now
          </button>
        </div>
      </div>
    </div>
  );
};

export default SignupSuccess;
