import Client from "../client";

export default {
  // returns list of all the user route
  login: (params, options) => Client("POST", "login", params, options),

  register: (params, options) =>
    Client("POST", "register/user", params, options),

  logout: (params, options) => Client("POST", "logout", params, options),

  getUserAllServices: (options) =>
    Client("GET", "all-service-list", undefined, options),
};
