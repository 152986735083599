import React, { useEffect, useState } from "react";
import BackIntro from "../Components/Cards/BackIntro";
import useTitle from "../Hooks/useTitle";
import { GetCourseLeasonApiService } from "../api/ApiServices";
import { Link, useNavigate } from "react-router-dom";
import { toast_error } from "../Toast/Toast";
import scrollToTop from "../Components/ScrollTop/ScrollTop";

const CapitoliSelection = () => {
  useTitle("Capitoli Selection");
  const navigate = useNavigate();
  const [leasons, setLeasons] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    GetCourseLeasonApiService(
      "patente",
      (response) => {
        if (response?.lessons?.length > 1) {
          let filterQuizList = [];
          response.lessons.map((item, index) => {
            if (index > 0) {
              let quiz = {
                id: item.id,
                class_name: item.class_name,
                isSelected: false,
              };
              filterQuizList.push(quiz);
            }
          });
          setLeasons(filterQuizList);
        }

        setIsLoading(false);
      },
      () => setIsLoading(false)
    );
  }, []);

  React.useEffect(() => {
    scrollToTop();
  }, []);

  const onCheckBoxClicked = (index) => {
    let newArray = [...leasons];
    newArray[index].isSelected = !newArray[index].isSelected;
    setLeasons(newArray);
  };

  const onContinuePressed = () => {
    const selectedIds = leasons.reduce((result, item) => {
      if (item.isSelected) {
        return [...result, item.id];
      }
      return result;
    }, []);

    if (selectedIds.length === 0) {
      return toast_error("Please select at least one capitoli.");
    }

    // console.log('selectedIds', selectedIds);
    navigate("/patente/quiz/capitoli", { state: { capitoliIds: selectedIds } });
  };

  // console.log("leasons", leasons);
  return (
    <div className="quiz_container">
      <BackIntro title="Quiz" />

      <div className="quiz_main">
        <h2 className="c_title">Capitoli selection</h2>
        <p className="c_text">Please select chapter first</p>

        <div className="course_demo_info">
          {isLoading ? (
            <div className="centerLoading">
              {" "}
              <div className="loading_circle"></div>
            </div>
          ) : (
            <ul className="course_days">
              {leasons.map((item, index) => (
                <li key={item.id}>
                  <input
                    type="checkbox"
                    checked={item.isSelected}
                    onClick={() => onCheckBoxClicked(index)}
                  />
                  <Link key={item.id} onClick={() => onCheckBoxClicked(index)}>
                    {item.class_name}
                  </Link>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
      <div className="button_div req_submit">
        <button className="btn" onClick={onContinuePressed}>
          Continue
        </button>
      </div>
    </div>
  );
};

export default CapitoliSelection;
