import React from "react";
import Utils from "../../utils/Utils";

const QuestionSpan = ({ item }) => {
  const [showTranslatePopover, setShowTranslatePopover] = React.useState(false);
  const [showIndicator, setShowIndicator] = React.useState(false);
  const [translateText, setTranslateText] = React.useState("");
  const [test, setTest] = React.useState(false);

  // console.log(test)
  // React.useEffect(() => {
  //     const handleDocumentClick = () => {
  //         if (test) {
  //             setTest(false);
  //         }
  //     };
  //     // document.addEventListener("click", handleDocumentClick);
  // }, [test]);

  if (showTranslatePopover) {
    setTimeout(() => {
      setShowTranslatePopover(false);
    }, 2000);
  }

  const translateFromApi = async (text) => {
    setShowIndicator(true);
    setTest(true);
    try {
      const response = await Utils.translateApi(text);

      const data = await response.json();
      const translatedText = data.responseData.translatedText;
      console.log(translatedText);
      setShowIndicator(false);
      setTranslateText(translatedText);
      setShowTranslatePopover(true);
    } catch (error) {
      setShowIndicator(false);
      console.error("Translation error:", error);
    }
  };

  return (
    <span onClick={(event) => translateFromApi(item, event)}>
      {`${item}`} &nbsp;
      {showIndicator && (
        <span className="indicator">
          <div className="loading_circle_btn"></div>
        </span>
      )}
      {showTranslatePopover && (
        <span className="indicator">{translateText}</span>
      )}
    </span>
  );
};

export default QuestionSpan;
