import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import BackIntro from "../../Components/Cards/BackIntro";
import { AuthContext } from "../../Context/AuthProvider";
import fetchEducationData from "../../utils/EducationUtils";
import scrollToTop from "../../Components/ScrollTop/ScrollTop";

const Lessons = () => {
  const { course } = useParams();
  const {
    patenteLessons,
    a2b1Lessons,
    cafLessons,
    setPatenteLessons,
    setA2b1Lessons,
    setCafLessons,
  } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);

  const getLessons = () => {
    if (course === "patente") {
      return patenteLessons;
    } else if (course === "a2b1") {
      return a2b1Lessons;
    } else if (course === "caf") {
      return cafLessons;
    } else {
      return { lessons: [] };
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (
          patenteLessons === undefined &&
          a2b1Lessons === undefined &&
          cafLessons === undefined
        ) {
          setIsLoading(true);
          const { patenteLessons, a2b1Lessons, cafLessons } =
            await fetchEducationData();
          setPatenteLessons(patenteLessons);
          setA2b1Lessons(a2b1Lessons);
          setCafLessons(cafLessons);
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [
    patenteLessons,
    a2b1Lessons,
    cafLessons,
    setPatenteLessons,
    setA2b1Lessons,
    setCafLessons,
  ]);

  React.useEffect(() => {
    scrollToTop();
  }, []);

  const displayCourse = getLessons();
  // console.log("displayCourse", displayCourse);
  if (isLoading) {
    return (
      <div className="centerLoading">
        {" "}
        <div className="loading_circle"></div>
      </div>
    );
  } else {
    return (
      <div className="course_contaner">
        <BackIntro title="Courses" />
        <div className="couser_main">
          <h2 className="c_title">{displayCourse?.name}</h2>
          <p className="c_text">
            There will be cost for this service{" "}
            <span>{`Fee ${displayCourse?.price} Euro`}</span>
          </p>
          <div className="course_demo_info">
            <ul className="course_days">
              {displayCourse?.lessons?.map((item, index) => (
                <li key={item.id}>
                  {index === 0 || displayCourse.user_has_course ? (
                    <Link
                      to={`/${course}/lessons/${item.id}`}
                      state={{ data: item }}
                    >
                      {item.class_name}
                    </Link>
                  ) : (
                    <span>{item.class_name}</span>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  }
};

export default Lessons;
