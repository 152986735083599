import React from "react";
import { AuthContext } from "../../Context/AuthProvider";
import edit_icon from "../../Assets/edit-icon.svg";
import { toast_error } from "../../Toast/Toast";
import {
  EditProfileApiService,
  GetProfileApiService,
} from "../../api/ApiServices";
import EditProfileModal from "../Modal/EditProfileModal";
import ReactModal from "react-modal";
import useTitle from "../../Hooks/useTitle";
import countries from "react-select-country-list";
import scrollToTop from "../ScrollTop/ScrollTop";

const ProfileInfo = () => {
  const { user, setIsLoading, saveUserProfile } = React.useContext(AuthContext);

  useTitle(user?.first_name + " " + user?.last_name);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  React.useEffect(() => {
    scrollToTop();
  }, []);

  const options = countries().getData();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [residenceCountry, setResidenceCountry] = React.useState(null);
  const [address, setAddress] = React.useState(null);
  const [postCode, setPostCode] = React.useState(null);
  const [city, setCity] = React.useState(null);

  React.useEffect(() => {
    const selectedCountry = options.find(
      (option) => option.value === user?.residence_country
    );

    setResidenceCountry(selectedCountry?.label);
    setAddress(user?.address);
    setPostCode(user?.post_code);
    setCity(user?.city);
  }, [user, options]);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const successCallback = (dataParse) => {
    GetProfileApiService((response) => saveUserProfile(response));
    setIsLoading(false);
    closeModal();
  };

  const failedCallback = () => {
    setIsLoading(false);
    closeModal();
  };

  const save_info = (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (residenceCountry.length <= 0) {
      return toast_error("validation.enter_residence_country");
    } else if (city.length <= 0) {
      return toast_error("validation.enter_city");
    } else if (address.length <= 0) {
      return toast_error("validation.enter_address");
    } else if (postCode.length <= 0) {
      return toast_error("validation.enter_postcode");
    }

    let defaultValue = null;
    if (user?.residence_country) {
      if (user.residence_country.length > 2) {
        const selectedOption = options.find(
          (option) => option.label === user.residence_country
        );
        if (selectedOption) {
          defaultValue = selectedOption.value;
        }
      } else {
        defaultValue = user.residence_country;
      }
    }

    const data = {
      residence_country: defaultValue,
      address: address,
      post_code: postCode,
      city: city,
    };

    EditProfileApiService(data, successCallback, failedCallback);
  };

  const props = {
    save_info,
    setCity,
    setAddress,
    setPostCode,
    setResidenceCountry,
    closeModal,
  };

  return (
    <>
      <div className="info_sec">
        <div className="basic_details">
          <h3>Basic Details</h3>
          <button onClick={openModal}>
            <img src={edit_icon} alt="" /> Edit profile
          </button>
        </div>
        <div className="basic_info">
          <ul>
            <li>
              <span>Full Name</span>
              <span>
                {user?.first_name} {user?.last_name}
              </span>
            </li>
            <li>
              <span>Date of Birth</span>
              <span>{user?.dob}</span>
            </li>
            <li>
              <span>Address</span>
              <span>{user?.address}</span>
            </li>
            <li>
              <span>Birth Place</span>
              <span>{user?.birth_place}</span>
            </li>
            <li>
              <span>City</span>
              <span>{user?.city}</span>
            </li>
            <li>
              <span>Residence Country</span>
              <span>{residenceCountry}</span>
            </li>
          </ul>
        </div>
      </div>
      <ReactModal
        isOpen={modalIsOpen}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Edit Modal"
        onRequestClose={closeModal}
      >
        <EditProfileModal props={props} />
      </ReactModal>
    </>
  );
};

export default ProfileInfo;
