import Client from "../client";

export default {
  // returns list of all the Visa route
  getVisaCountries: (options) =>
    Client("GET", "visa-countries", undefined, options),

  getVisas: (visaType, countryCode, options) =>
    Client("GET", `visas/${visaType}/${countryCode}`, undefined, options),

  getVisaDetails: (id, options) =>
    Client("GET", `visa/${id}`, undefined, options),

  getUserVisas: (options) => Client("GET", "user-visas", undefined, options),

  getUserVisaDetails: (id, options) =>
    Client("GET", `user-visa/${id}`, undefined, options),

  validateRequestVisa: (params, options) =>
    Client("POST", "validate-visa-request", params, options),

  requestVisa: (params, options) =>
    Client("POST", "request-visa", params, options),
};
