import React from "react";
import AppSuggestion from "../AppSugestoion/AppSuggestion";
import SecCard from "../Cards/SecCard";
import useTitle from "../../Hooks/useTitle";
import scrollToTop from "../ScrollTop/ScrollTop";

const VisaAndOthers = () => {
  useTitle("Visa And Others");

  React.useEffect(() => {
    scrollToTop();
  }, []);

  const services = [
    {
      id: 1,
      title: "VISA",
      title_b: "ভিসা",
      to: "/visa",
    },
    {
      id: 2,
      title: "Tickets",
      title_b: "টিকেটস",
      to: "/ticket",
    },
    {
      id: 3,
      title: "Tours & Travels",
      title_b: "টুরস-ট্রাভেলস",
      to: "/tour",
    },
  ];

  return (
    <div>
      <div className="services visa">
        {services.map((item) => (
          <SecCard item={item} key={item.id} />
        ))}
      </div>

      <AppSuggestion />
    </div>
  );
};

export default VisaAndOthers;
