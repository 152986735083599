import React from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../Assets/logo.png";
import useTitle from "../../Hooks/useTitle";
import { RegistrationApiService } from "../../api/ApiServices";

import { AuthContext } from "../../Context/AuthProvider";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Select from "react-select";
import countries from "react-select-country-list";
import { toast_error } from "../../Toast/Toast";
import scrollToTop from "../ScrollTop/ScrollTop";

const Signup = () => {
  useTitle("Sign Up");

  const { user, isLoading, setIsLoading } = React.useContext(AuthContext);

  const [phone, setPhone] = React.useState("39");
  const [first_name, setFirst_name] = React.useState("");
  const [last_name, setLast_name] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [birth_place, setBirth_place] = React.useState("");
  const [city, setCity] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [dob, setDob] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [post_code, setPostCode] = React.useState("");
  const [residence_country, setResidence] = React.useState("");

  const options = countries().getData();

  const nevigate = useNavigate();

  React.useEffect(() => {
    if (user) {
      nevigate("/");
    }
  }, [user, nevigate]);

  React.useEffect(() => {
    scrollToTop();
  }, []);

  const successCallback = () => {
    setIsLoading(false);
    nevigate("/success-signup");
  };

  const failedCallback = () => {
    setIsLoading(false);
  };

  const handleSignup = (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (
      password.length < 8 &&
      !/[A-Z]/.test(password) &&
      !/[^a-zA-Z0-9]/.test(password)
    ) {
      toast_error(
        "Password must have at least 6 characters, uppercase, lowercase, and special character"
      );
      setIsLoading(false);
      return; // Password does not meet the requirements
    }

    const passData = {
      first_name,
      last_name,
      address,
      birth_place,
      city,
      country: country.value,
      dob,
      password,
      email,
      phone_number: phone,
      post_code,
      residence_country: residence_country.value,
    };

    RegistrationApiService(passData, successCallback, failedCallback);
  };

  return (
    <div className="signup_main">
      <div className="signup_container">
        <div className="center_logo">
          <img className="logo" src={logo} alt="" />
          <h3>Sign up as new member</h3>
        </div>
        <form onSubmit={handleSignup} className="signup_form">
          <div>
            <div className="signup_input">
              <div className="input_div">
                <label htmlFor="first_name">First Name</label>
                <br />
                <input
                  type="text"
                  name="first_name"
                  required
                  onChange={(e) => setFirst_name(e.target.value)}
                  placeholder="Enter your first name"
                />
              </div>
              <div className="input_div">
                <label htmlFor="last_name">Last Name</label>
                <br />
                <input
                  type="text"
                  name="last_name"
                  required
                  onChange={(e) => setLast_name(e.target.value)}
                  placeholder="Enter your last name"
                />
              </div>
            </div>
            <div className="signup_input">
              <div className="input_div">
                <label htmlFor="phone_number">Phone Number</label>

                <PhoneInput
                  className="phone_input"
                  country={"it"}
                  placeholder="Enter phone number"
                  value={phone}
                  required
                  onChange={(value) => {
                    setPhone(value);
                  }}
                />
              </div>
              <div className="input_div">
                <label htmlFor="dob">Date of Birth</label>
                <input
                  required
                  type="date"
                  min="1900-01-01"
                  max="2099-12-31"
                  name="dob"
                  onChange={(e) => setDob(e.target.value)}
                />
              </div>
            </div>
            <div className="signup_input">
              <div className="input_div">
                <label htmlFor="birth_place">Birth Place</label>
                <input
                  required
                  type="text"
                  placeholder="Enter your birth place"
                  name="birth_place"
                  onChange={(e) => setBirth_place(e.target.value)}
                />
              </div>
              <div className="input_div">
                <label htmlFor="country">Country</label>
                <Select
                  required
                  options={options}
                  isClearable
                  onChange={(value) => setCountry(value)}
                />
              </div>
            </div>
            <div className="signup_input">
              <div className="input_div">
                <label htmlFor="residence_country">Residence Country</label>

                <Select
                  required
                  options={options}
                  isClearable
                  onChange={(value) => setResidence(value)}
                />
              </div>
              <div className="input_div">
                <label htmlFor="address">Address</label>
                <input
                  required
                  type="text"
                  placeholder="Enter your address"
                  name="address"
                  onChange={(e) => setAddress(e.target.value)}
                />
              </div>
            </div>
            <div className="signup_input">
              <div className="input_div">
                <label htmlFor="post_code">Post Code</label>
                <input
                  required
                  type="number"
                  placeholder="Enter your post code"
                  name="post_code"
                  onChange={(e) => setPostCode(e.target.value)}
                />
              </div>
              <div className="input_div">
                <label htmlFor="city">City</label>
                <br />
                <input
                  required
                  type="text"
                  placeholder="Enter your city"
                  name="city"
                  onChange={(e) => setCity(e.target.value)}
                />
              </div>
            </div>
            <div className="signup_input">
              <div className="input_div">
                <label htmlFor="mail">Email</label>
                <input
                  required
                  type="email"
                  placeholder="Enter your email"
                  name="email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="input_div">
                <label htmlFor="">Password</label>
                <br />
                <input
                  required
                  type="password"
                  placeholder="Enter your password"
                  name="password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="signup_submit">
            <p>
              By logging, you agree to our <Link>Terms & Conditions</Link> and{" "}
              <Link>PrivacyPolicy</Link>.
            </p>
            <button
              disabled={isLoading ? true : false}
              className={`btn ${isLoading ? "disabled" : ""}`}
            >
              {isLoading ? (
                <div className="loading_circle_btn"></div>
              ) : (
                "Sign Up"
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Signup;
