import Client from "../client";

export default {
  // returns list of all the Benefit route
  getBenefits: (options) => Client("GET", "benefits", undefined, options),

  getBenefitDetails: (id, options) =>
    Client("GET", `benefit/${id}`, undefined, options),

  getUserBenefits: (options) =>
    Client("GET", "user-benefits", undefined, options),

  getUserBenefitDetails: (id, options) =>
    Client("GET", `user-benefit/${id}`, undefined, options),

  validateRequestBenefit: (params, options) =>
    Client("POST", "validate-benefit-request", params, options),

  requestBenefit: (params, options) =>
    Client("POST", "request-benefit", params, options),
};
