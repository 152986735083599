import React from "react";
import { Link } from "react-router-dom";

const SecCard = ({ item }) => {
  return (
    <Link to={item.to} className="s_sec_1 s_sec">
      <div className="">
        <span>
          {item.title} <br />
          {item?.title_b}
        </span>
        <img src={item?.img} alt="" />
      </div>
    </Link>
  );
};

export default SecCard;
