import React from "react";
import Select from "react-select";
import countries from "react-select-country-list";
import { AuthContext } from "../../Context/AuthProvider";

const EditProfileModal = ({ props }) => {
  const {
    save_info,
    setCity,
    setAddress,
    setPostCode,
    setResidenceCountry,
    closeModal,
  } = props;

  const { user, isLoading } = React.useContext(AuthContext);
  const options = countries().getData();

  return (
    <div>
      <div>
        <h3 className="c_title">Edit Your Information</h3>
      </div>
      <form onSubmit={save_info} className="modal_form">
        <div className="signup_input">
          <div className="input_div">
            <label htmlFor="city">City</label>
            <br />
            <input
              type="text"
              name="city"
              defaultValue={user?.city}
              required
              onChange={(e) => setCity(e.target.value)}
              placeholder="Enter your last name"
            />
          </div>
          <div className="input_div">
            <label htmlFor="address">Address</label>
            <br />
            <input
              type="text"
              name="address"
              defaultValue={user?.address}
              required
              onChange={(e) => setAddress(e.target.value)}
              placeholder="Enter your last name"
            />
          </div>
        </div>
        <div className="signup_input">
          <div className="input_div">
            <label htmlFor="post_code">Post Code</label>
            <br />
            <input
              type="text"
              name="post_code"
              defaultValue={user?.post_code}
              required
              onChange={(e) => setPostCode(e.target.value)}
              placeholder="Enter your first name"
            />
          </div>
          <div className="input_div">
            <label htmlFor="residence_country">Residence Country</label>
            <br />
            <Select
              options={options}
              defaultValue={options.find((option) => option.value === user?.residence_country)}
              isClearable
              onChange={(selectedOption) => setResidenceCountry(selectedOption?.value || '')}
            />
          </div>
        </div>
        <button disabled={isLoading ? true : false} className="btn">
          {isLoading ? <div className="loading_circle_btn"></div> : "Save"}
        </button>
      </form>
      <button className="btn close_modal" onClick={closeModal}>
        Close
      </button>
    </div>
  );
};

export default EditProfileModal;
