import Client from "../client";

export default {
  // returns list of all the Tour route
  getTours: (options) => Client("GET", "tours", undefined, options),

  getTourDetails: (id, options) =>
    Client("GET", `tour/${id}`, undefined, options),

  getUserTours: (options) => Client("GET", "user-tours", undefined, options),

  getUserTourDetails: (id, options) =>
    Client("GET", `user-tour/${id}`, undefined, options),

  validateRequestTour: (params, options) =>
    Client("POST", "validate-tour-request", params, options),

  requestTour: (params, options) =>
    Client("POST", "request-tour", params, options),
};
