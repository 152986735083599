import React, { useState, useEffect } from "react";
import caf_service from "../Assets/services/caf-service.png";
import visa_service from "../Assets/services/visa (2).png";
import edu_service from "../Assets/services/education.png";
import rate from "../Assets/services/rate.png";
import patente from "../Assets/education-service/patente.png";
import course from "../Assets/education-service/course.png";
import car from "../Assets/other-service/car.webp";
import home from "../Assets/other-service/home.webp";
import influencer from "../Assets/other-service/Influencer.webp";
import AppSuggestion from "../Components/AppSugestoion/AppSuggestion";
import ServiceCard from "../Components/Cards/ServiceCard";
import AllCafService from "../Components/Services/AllCafService";
import useTitle from "../Hooks/useTitle";
import SecCard from "../Components/Cards/SecCard";
import { getRequest } from "../api/ApiRequest";
import { SUCCESS } from "../api/ApiServices";
import Urls from "../api/Urls";
import { Link, useNavigate } from "react-router-dom";
import scrollToTop from "../Components/ScrollTop/ScrollTop";

const Home = () => {
  useTitle("Home");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [cafServices, setCafServices] = useState([]);
  const [tourServices, setTourServices] = useState([]);
  const [embassyLink, setEmbassyLink] = useState([]);

  useEffect(() => {
    fetchDashboardData();
  }, []);

  React.useEffect(() => {
    scrollToTop();
  }, []);

  const fetchDashboardData = async () => {
    setIsLoading(true);
    try {
      const [jsonCafs, jsonTours, jsonEmbassyLink] = await Promise.all([
        getRequest(Urls.cafServices),
        getRequest(Urls.tourServices),
        getRequest(Urls.links),
      ]);
      const parseCafs = JSON.parse(jsonCafs);
      const parseTours = JSON.parse(jsonTours);
      const parseEmbassyLink = JSON.parse(jsonEmbassyLink);

      setIsLoading(false);
      setCafServices(parseCafs?.status === SUCCESS ? parseCafs?.services : []);
      setTourServices(parseTours?.status === SUCCESS ? parseTours?.tours : []);
      setEmbassyLink(
        parseEmbassyLink?.status === SUCCESS ? parseEmbassyLink?.links : []
      );

      // console.log("parseEmbassyLink", parseEmbassyLink);
    } catch (error) {
      setIsLoading(false);
      console.log("Error on fetchApiData in Home ", error);
    }
  };

  const s_sec = [
    {
      id: 100,
      title: "CAF SERVICE",
      title_b: "কাফ সার্ভিস",
      to: "/caf-service",
      img: caf_service,
    },
    {
      id: 101,
      title: "VISA-TICKETS-TRAVEL",
      title_b: "ভিসা-টিকেট-ট্রাভেল",
      to: "/visa&others",
      img: visa_service,
    },
    {
      id: 102,
      title: "EDUCATION & COURSE ",
      title_b: "পাতেন্তে-A2-B1 সার্টিফিকেট ",
      to: "/education",
      img: edu_service,
    },
    {
      id: 103,
      title: "MONEY TRANSFER RATE",
      title_b: "মানি ট্রান্সফার হার",
      to: "/money-transfer-rate",
      img: rate,
    },
  ];

  // if (isLoading) {
  //   return (
  //     <div className="centerLoading">
  //       {" "}
  //       <div className="loading_circle"></div>
  //     </div>
  //   );
  // } else {
  return (
    <div>
      <div className="container">
        <div className="services">
          {s_sec.map((item) => (
            <SecCard item={item} key={item.id} />
          ))}
        </div>
        <AppSuggestion />

        <AllCafService
          label="CAF Services"
          services={cafServices}
          showNumber={10}
        />
      </div>

      {tourServices.length > 0 && (
        <div className="container">
          <div className="VT_main">
            <h3>Visa tickets ,Tour & travels </h3>
            <div className="visa_ticket_travel">
              {tourServices.map((tourItem, index) => (
                <Link
                  style={{ textDecoration: "none" }}
                  to={`/service-requerment/tour/${tourItem.id}`}
                  className="tour-item" // Use "tour_item" instead of "tsc_item"
                  key={index}
                >
                  <div
                    className="tour_img_container"
                    style={{
                      backgroundImage: `url(${
                        Urls.storagePath + tourItem.thumbnail_pic
                      })`,
                    }}
                  ></div>
                  <span>{tourItem.name}</span>
                </Link>
              ))}
            </div>
          </div>
        </div>
      )}

      <div className="ed_container">
        <div className="container">
          <h3>Education Service</h3>
          <div className="ed_main">
            <div className="ed_item">
              <img src={patente} alt="" />
              <div>
                <span>PATENTE(ঈসে)</span>
                <span>Theory(ঈসে)</span>
                <span>Quiz(ঈসে)</span>
                <button onClick={() => navigate("/patente")} className="btn">
                  View details
                </button>
              </div>
            </div>
            <div className="ed_item">
              <img src={course} alt="" />
              <div>
                <span>A2 B1 CERTIFICATE COURSE</span>
                <span className="def_date">DAY 1- DAY 30</span>
                <button
                  onClick={() => navigate("/a2b1/lessons")}
                  className="btn"
                >
                  View details
                </button>
              </div>
            </div>
            <div className="ed_item">
              <img src={course} alt="" />
              <div>
                <span>CAF COURSE</span>
                <span className="def_date">DAY 1- DAY 30</span>
                <button
                  onClick={() => navigate("/caf/lessons")}
                  className="btn"
                >
                  View details
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {embassyLink.length > 0 && (
        <div className="container">
          <div className="embassy_main">
            <h3>Embassy Service</h3>
            <div className="embassy">
              {embassyLink.map((linkItem) => (
                <ServiceCard
                  type={"embassy"}
                  service={linkItem}
                  key={linkItem.id}
                />
              ))}
            </div>
          </div>
        </div>
      )}

      <div className="container">
        <div className="other_main">
          <h3>Other Service</h3>
          <div className="other_service">
            <div>
              <img src={influencer} alt="" />
              <Link to="insurance" className="other_text def_text">
                Insurance
              </Link>
            </div>
            <div>
              <img src={car} alt="" />
              <Link to="/car-service" className="other_text">
                Car Service
              </Link>
            </div>
            <div>
              <img src={home} alt="" />
              <Link to="/ads" className="other_text def_text">
                Advertisement
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  // }
};

export default Home;
