import Client from "../client";

export default {
  // returns list of all the Insurances route
  getUserInsurances: (options) =>
    Client("GET", "insurances", undefined, options),

  getUserInsuranceDetails: (id, options) =>
    Client("GET", `insurance/${id}`, undefined, options),

  requestInsurance: (params, options) =>
    Client("POST", "insurances", params, options),
};
