import Client from "../client";

export default {
  // returns list of all the Course route
  getCourseLessons: (type, options) =>
    Client("GET", `course/${type}/lessons`, undefined, options),

  getCourseDetails: (id, options) =>
    Client("GET", `course/${id}`, undefined, options),

  getUserCourses: (options) =>
    Client("GET", "user-courses", undefined, options),

  getUserCourseDetails: (id, options) =>
    Client("GET", `user-course/${id}`, undefined, options),

  validateRequestCourse: (params, options) =>
    Client("POST", "validate-course-request", params, options),

  requestCourse: (params, options) =>
    Client("POST", "request-course", params, options),
};
