import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../Context/AuthProvider";
import SecCard from "../Cards/SecCard";
import AppSuggestion from "../AppSugestoion/AppSuggestion";
import { GetUserCourseApiService, SUCCESS } from "../../api/ApiServices";
import TakenService from "../Services/TakenServices";
import useTitle from "../../Hooks/useTitle";
import { authenticateGetRequest } from "../../api/ApiRequest";
import Urls from "../../api/Urls";
import fetchEducationData from "../../utils/EducationUtils";
import scrollToTop from "../ScrollTop/ScrollTop";

const services = [
  {
    id: 1,
    title: "PATENTE",
    title_b: "পাতেন্তে",
    to: "/patente",
  },
  {
    id: 2,
    title: "A2 B1 CERTIFICATE",
    title_b: "A2 B1 সার্টিফিকেট",
    to: "/a2b1/lessons",
  },
  {
    id: 3,
    title: "CAF",
    title_b: "কাফ",
    to: "/caf/lessons",
  },
];

// useEffect(() => {
//   scrollToTop();
// }, []);

const Education = () => {
  useTitle("Education");
  const { user, setPatenteLessons, setA2b1Lessons, setCafLessons } =
    useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [userCourseList, setUserCourseList] = useState([]);

  useEffect(() => {
    async function fetchData() {
      if (user) {
        setIsLoading(true);
        GetUserCourseApiService(
          (response) => {
            setUserCourseList(response);
            setIsLoading(false);
          },
          () => null
        );
        try {
          const { patenteLessons, a2b1Lessons, cafLessons } =
            await fetchEducationData();
          setPatenteLessons(patenteLessons);
          setA2b1Lessons(a2b1Lessons);
          setCafLessons(cafLessons);
        } catch (error) {
        } finally {
          setIsLoading(false);
        }
      }
    }

    fetchData();
  }, [user]);

  React.useEffect(() => {
    scrollToTop();
  }, []);

  // useEffect(() => {
  //   if (user) {
  //     setIsLoading(true);
  //     GetUserCourseApiService(
  //       (response) => {
  //         setUserCourseList(response);
  //         setIsLoading(false);
  //       },
  //       () => null
  //     );
  //   }

  //   (async () => {
  //     try {
  //       const [jsonPatente, jsonA2b1, jsonCaf] = await Promise.all([
  //         authenticateGetRequest(`${Urls.getCourseLeason}/patente/lessons`),
  //         authenticateGetRequest(`${Urls.getCourseLeason}/a1b2/lessons`),
  //         authenticateGetRequest(`${Urls.getCourseLeason}/caf/lessons`),
  //       ]);
  //       const parsePatente = JSON.parse(jsonPatente);
  //       const parseA2b1 = JSON.parse(jsonA2b1);
  //       const parseCaf = JSON.parse(jsonCaf);

  //       setPatenteLessons(
  //         parsePatente?.status === SUCCESS ? parsePatente.course_details : {}
  //       );
  //       setA2b1Lessons(
  //         parseA2b1?.status === SUCCESS ? parseA2b1.course_details : {}
  //       );
  //       setCafLessons(
  //         parseCaf?.status === SUCCESS ? parseCaf.course_details : {}
  //       );

  //     } catch (error) {}
  //   })();
  // }, [user, setA2b1Lessons, setPatenteLessons, setCafLessons]);

  // console.log("userCourseList", userCourseList);
  return (
    <div className="container">
      {isLoading ? (
        <div className="centerLoading">
          <div className="loading_circle"></div>
        </div>
      ) : (
        userCourseList.length > 0 && (
          <TakenService type="course" takenList={userCourseList} />
        )
      )}
      <div className="services visa">
        {services.map((item) => (
          <SecCard item={item} key={item.id} />
        ))}
      </div>
      <AppSuggestion />
    </div>
  );
};

export default Education;
