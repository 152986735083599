import React, { useCallback } from 'react';
import upload from '../../Assets/upload.svg';
import { useDropzone } from 'react-dropzone';

const DragDrop = ({ handleImage, mainIndex=0, subIndex=0 }) => {
    
    const [selectedImage, setSelectedImage] = React.useState(null);

    const onDrop = useCallback((acceptedFiles) => {
        // Create a FormData object and append the selected file(s)
        const file = acceptedFiles[0];
        const reader = new FileReader();
        reader.onload = () => {
            setSelectedImage(reader.result);
        };
        reader.readAsDataURL(file);

        handleImage(file, mainIndex, subIndex);
    
      }, []);

    // const onDrop = (acceptedFiles) => {
    //     console.log(acceptedFiles);
    //     const file = acceptedFiles[0];
    //     const reader = new FileReader();
    //     reader.onload = () => {
    //         setSelectedImage(reader.result);
    //     };
    //     reader.readAsDataURL(file);
    // };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });


    return (
        <div {...getRootProps()} className={`upload_img ${isDragActive ? 'active' : ''}`}>
            {selectedImage ?
                <img src={selectedImage} alt=" Selected Img" />
                :
                <img src={upload} alt="Upload Icon" />
            }
            <input {...getInputProps()} accept="image/*" />

            {isDragActive ? (
                <p>Drop the profile picture here</p>
            ) : (
                <p>Drag and drop a file here, or click to select a file</p>
            )}
        </div>
    );
};

export default DragDrop;