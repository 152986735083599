import Client from "../client";

export default {
  // returns list of all the Car route
  createIssue: (params, options) =>
    Client("POST", "create-issue", params, options),

  updateIssue: (params, options) =>
    Client("POST", "update-issue-status", params, options),

  createReplyIssue: (params, options) =>
    Client("POST", "create-reply", params, options),

  getUserIssues: (options) => Client("GET", "issues", undefined, options),

  getIssueDetails: (id, options) =>
    Client("GET", `issue/${id}`, undefined, options),
};
