// educationUtils.js
import { authenticateGetRequest } from '../api/ApiRequest';
import { SUCCESS } from '../api/ApiServices';
import Urls from '../api/Urls';


const fetchEducationData = async () => {
    try {
        const [jsonPatente, jsonA2b1, jsonCaf] = await Promise.all([
            authenticateGetRequest(`${Urls.getCourseLeason}/patente/lessons`),
            authenticateGetRequest(`${Urls.getCourseLeason}/a1b2/lessons`),
            authenticateGetRequest(`${Urls.getCourseLeason}/caf/lessons`),
        ]);

        const parsePatente = JSON.parse(jsonPatente);
        const parseA2b1 = JSON.parse(jsonA2b1);
        const parseCaf = JSON.parse(jsonCaf);

        const patenteLessons = parsePatente?.status === SUCCESS ? parsePatente.course_details : {};
        const a2b1Lessons = parseA2b1?.status === SUCCESS ? parseA2b1.course_details : {};
        const cafLessons = parseCaf?.status === SUCCESS ? parseCaf.course_details : {};

        return { patenteLessons, a2b1Lessons, cafLessons };
    } catch (error) {
        throw error;
    }
};

export default fetchEducationData;