import Client from "../client";

export default {
  // returns list of all the Car route
  getUserCars: (options) => Client("GET", "car-services", undefined, options),

  getUserCarDetails: (id, options) =>
    Client("GET", `car-service/${id}`, undefined, options),

  requestCar: (params, options) =>
    Client("POST", "car-services", params, options),
};
